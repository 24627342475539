<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryRelatedImages',
  watch: {
    relatedImages() {
      this.$store.commit('setRelatedImages', this.relatedImages);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      slug: computed(() => store.state.data.relatedSlug || null),
    });

    const queryRelatedImages = gql`
      query relatedImages($slug: String!) {
        pages(filters: { slug: { contains: $slug } }) {
          data {
            attributes {
              Hintergrundbild {
                data {
                          attributes {
                            url
                            formats
                          }
                        }
              }
            }
          }
        }
      }
    `;
    const { data: relatedImages } = useQuery({
      query: queryRelatedImages,
      variables,
      context,
    });
    return { relatedImages };
  },
};
</script>
