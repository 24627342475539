<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryCurrentPageImages',
  watch: {
    currentPageImages() {
      this.$store.commit('setCurrentPageImages', this.currentPageImages);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      slug: computed(() => store.state.data.currentSlug),
    });

    const queryCurrentPageImages = gql`
      query ($slug: String!) {
        pages(filters: { slug: { contains: $slug } }) {
          data {
            attributes {
              Hintergrundbild {
                data {
                    attributes {
                      url
                      formats
                    }
                  }
              }
              Abschnitte {
                Media {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const { data: currentPageImages } = useQuery({
      query: queryCurrentPageImages,
      variables,
      context,
      cachePolicy: 'cache-first',
    });
    return { currentPageImages };
  },
};
</script>
