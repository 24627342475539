<template>
<div id="pricing" class="w-full h-auto md:h-auto text-new-white font-rouben px-8 md:px-20 md:pt-40 flex flex-col">
  <div class="leading-tight tracking-wide w-full md:w-5/12 font-extrabold md:font-bold text-4xl md:text-6xl text-center md:text-left">Preise</div>
  <div class="mt-5 w-full flex-grow flex flex-col md:flex-row justify-between gap-12">
    <div v-for="price in prices?.Preis"
      :key=price
      class="transition duration-500 ease-in-out border border-new-white p-5 flex flex-col justify-between hover:opacity-70 cursor-pointer"
      :class="{'w-full md:w-1/4' : prices.Preis.length === 4, 'w-full md:w-1/3' : prices.Preis.length === 3, 'w-full md:w-1/2' : prices.Preis.length === 2}">
      <div>
        <div class="font-bold text-4xl text-left">{{price?.Titel}}</div>
        <div v-html="convertMarkdown(price?.Beschreibung)" class="markdown whitespace-pre-line text-base leading-7 my-12 text-left" />
      </div>
      <div class="flex flex-col justify-center items-center pb-3 mt-8 md:mt-0">
        <div class="font-bold text-5xl">{{price?.Preis}} <p class="inline text-base font-medium">{{ price.pm ? 'p.m.' : '' }}</p></div>
        <div class="flex flex-col text-center mt-2">
          <div v-if="price.Reduced">{{price?.Reduced}} </div>
          <div v-if="price.ExtraReduced" class="ml-4">{{price?.ExtraReduced}}</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="prices?.Allgemein" class="w-full h-auto border border-new-white p-5 mt-10">
    <div  v-html="convertMarkdown(prices?.Allgemein)" class="text-sm w-full md:w-full text-new-white font-rouben markdown whitespace-pre-line leading-7 text-left" />
  </div>
</div>
<div v-if="prices?.Hinweise" v-html="convertMarkdown(prices?.Hinweise)" class="text-sm w-full md:w-full mt-5 text-new-white font-rouben px-8 md:px-20 markdown whitespace-pre-line leading-7 my-12 text-left" />
</template>

<script>
// Mixins
import convertMarkdown from '../mixins/convertMarkdown.vue';

export default {
  name: 'priceSection',
  props: ['prices'],
  mixins: [convertMarkdown],

  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    text() {
      return this.$store.state.lang.home[this.$store.state.lang.currentLang];
    },
  },
};
</script>
