<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryCurrentPage',
  watch: {
    currentPage() {
      this.$store.commit('setCurrentPage', this.currentPage);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'network-only',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
      slug: computed(() => store.state.data.currentSlug),
    });

    const queryCurrentPage = gql`
     query($slug: String!, $lang: I18NLocaleCode!) {
      pages(locale: $lang, filters: { slug: { contains: $slug } }) {
        data {
          attributes {
          Hintergrundbild {
                data {
                    attributes {
                      url
                      formats
                    }
                  }
              }
            page {
              data {
                attributes {
                  name
                  slug
                }
              }
            }
            name
            Titel
            Allgemeines
            Beschreibung
            TextRight
            Button_Text
            banner
            showOverview
            overviewText
            color
            Abschnitte {
              planoId
              Titel
              Inhalt
              Button
              couponButton
              Button_Text
              hide
              Media {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
            }
            Preise {
              Preis {
                Titel
                Beschreibung
                Preis
                Reduced
                ExtraReduced
              }
              Allgemein
              Hinweise
            }
            Seo {
                metaTitle
                metaDescription
              }
          }
        }
      }
    }
    `;

    const { data: currentPage } = useQuery({
      query: queryCurrentPage,
      variables,
      context,
    });

    return { currentPage };
  },
};
</script>
