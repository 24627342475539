<template>
<div id="page" class="w-full">
  <heroSection
    :color="currentPage?.pages?.data[0]?.attributes?.color"
  :titel=currentPage?.pages?.data[0]?.attributes?.Titel
  :subtext=currentPage?.pages?.data[0]?.attributes?.Beschreibung
  :rightText=currentPage?.pages?.data[0]?.attributes?.TextRight
  :image=currentPage?.pages?.data[0]?.attributes?.Hintergrundbild?.data?.attributes
  :label1="currentPage?.pages?.data[0]?.attributes?.Button_Text ? currentPage?.pages?.data[0]?.attributes?.Button_Text : null"
  :label2="currentPage?.pages?.data[0]?.attributes?.Preise ? text?.prices : null"
  scrollTo1="section"
  scrollTo2="pricing" />

  <!-- running line -->
  <div v-if="currentPage?.pages?.data[0]?.attributes?.banner" class="w-full whitespace-nowrap font-rouben tracking-widest py-12 font-bold -mt-36 select-none" :style="{'color': currentPage?.pages?.data[0]?.attributes?.color}">
    <div class="text-5xl uppercase mt-3 lg:mt-5 flex flex-row">
      <marquee-text :repeat="12" :key="currentPage?.pages?.data[0]?.attributes?.banner">
        <div class="mr-12">{{ currentPage?.pages?.data[0]?.attributes?.banner }}</div>
      </marquee-text>
    </div>
  </div>

<div class="w-full md:flex md:flex-col md:items-center font-rouben">

  <div v-if="currentPage?.pages?.data[0]?.attributes?.Allgemeines" class="w-full h-auto bg-new-black px-8 md:p-20 font-rouben md:max-w-screen-2xl text-new-white md:px-20 my-5 pt-24 md:pt-40 flex flex-col md:flex-row justify-center items-center">
    <div v-html="convertMarkdown(currentPage?.pages?.data[0]?.attributes?.Allgemeines)" class="md:w-full markdown whitespace-pre-line text-base mb-3 leading-7 mt-3 lg:mt-0 text-center" />

  </div>

  <div v-if="currentPage?.pages?.data[0]?.attributes?.showOverview" id="section" class="w-full md:max-w-screen-2xl">
    <div class="text-white text-6xl font-bold my-5 mt-36 w-full text-center px-8 md:px-20">{{currentPage?.pages?.data[0]?.attributes?.overviewText}}</div>
    <div class="flex flex-col md:flex-row md:flex-wrap px-8 md:px-20 justify-center items-center">
      <div v-for="(section, index) in currentPage?.pages?.data[0]?.attributes?.Abschnitte" :key=section @click="selectedCourse = index" class="w-full md:w-auto">
        <div v-if="!section?.hide" class="w-full md:w-auto transition duration-500 ease-in-out mt-2 md:m-2 border px-9 py-4 rounded-lg cursor-pointer flex flex-row justify-center items-center select-none group hover:bg-new-white" :class="{'bg-new-white' : selectedCourse === index }">
          <div class="transition duration-500 ease-in-out text-white text-xl group-hover:text-new-black text-center" :class="{'text-new-black' : selectedCourse === index }">{{ section?.Titel }}</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="abschnitteCnt > 0" :id="currentPage?.pages?.data[0]?.attributes?.showOverview ? '' : 'section'" class="w-full md:max-w-screen-2xl">
    <transition @enter="fadeIn" @leave="fadeOut" :css="false" mode="out-in">
      <infoSection
        :key="selectedCourse"
        :titel=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.Titel
        :text=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.Inhalt
        :button=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.Button
        :couponButton=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.couponButton
        :button_text=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.Button_Text
        :planoId=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.planoId
        :url=currentPage?.pages?.data[0]?.attributes?.Abschnitte[selectedCourse]?.Media?.data?.attributes
      />
    </transition>
  </div>
  <div id="prices" v-if="currentPage?.pages?.data[0]?.attributes?.Preise" class="md:max-w-screen-2xl">
    <priceSection :prices=currentPage?.pages?.data[0]?.attributes?.Preise />
  </div>
</div>

  <div v-if="relatedImages && currentPage?.pages?.data[0]?.attributes?.page?.data?.attributes?.slug">
    <div class="w-full h-screen md:h-50 mt-20 font-rouben text-new-white cursor-pointer group" @click="goTo(currentPage?.pages?.data[0]?.attributes?.page?.data?.attributes?.slug)">
     <div class="relative w-full h-full  flex flex-col justify-end md:justify-center items-center md:items-start overflow-hidden ">
       <div class="transition transform-gpu duration-1000 ease-in-out absolute  px-8 md:px-20 w-full h-full bg-cover bg-no-repeat bg-center group-hover:scale-105 filter brightness-75 hover:brightness-100" :style="{ backgroundImage: 'url(' + relatedImages?.pages?.data[0]?.attributes?.Hintergrundbild?.data?.attributes?.url + ')' }"></div>
       <div class="absolute  px-8 md:px-20 flex flex-col md:items-start justify-center items-center">
        <div class="text-lg">{{ text?.next }}</div>
        <div class="leading-tight mt-3 mb-24 md:mb-0 tracking-wide w-full md:w-10/12 font-extrabold md:font-bold text-6xl md:text-5xl text-center md:text-left">{{currentPage?.pages?.data[0]?.attributes?.page?.data?.attributes?.name}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full md:flex md:flex-col md:items-center">
    <footerComponent />
  </div>
  <queryCurrentPage />
  <queryCurrentPageImages />
  <queryRelatedImages v-if="currentPage?.pages?.data[0]?.attributes?.page?.data?.attributes?.slug" />
</div>
</template>

<script>
// eslint-disable-next-line import/extensions
import anime from 'animejs/lib/anime.es.js';

import MarqueeText from 'vue-marquee-text-component';

// GraphQL
import queryCurrentPage from '../graphql/queryCurrentPage.vue';
import queryCurrentPageImages from '../graphql/queryCurrentPageImages.vue';
import queryRelatedImages from '../graphql/queryRelatedImages.vue';

// Components
import footerComponent from '../components/footer.vue';
import heroSection from '../components/heroSection.vue';
import infoSection from '../components/infoSection.vue';
import priceSection from '../components/priceSection.vue';

import convertMarkdown from '../mixins/convertMarkdown.vue';

export default {
  name: 'Pages',
  components: {
    heroSection, footerComponent, queryCurrentPage, queryCurrentPageImages, infoSection, priceSection, queryRelatedImages, MarqueeText,
  },
  mixins: [convertMarkdown],

  data() {
    return {
      selectedCourse: null,
    };
  },
  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    currentPage() {
      return this.$store.state.data.currentPage;
    },
    abschnitteCnt() {
      let count = 0;
      const abschnitte = this.currentPage?.pages?.data[0]?.attributes?.Abschnitte;
      if (abschnitte && abschnitte.length > 0) {
        abschnitte.forEach((abschnitt) => {
          if (!abschnitt?.hide) count += 1;
        });
      }
      return count;
    },
    currentPageImages() {
      return this.$store.state.data.currentPageImages;
    },
    relatedImages() {
      return this.$store.state.data.relatedImages;
    },
    text() {
      return this.$store.state.lang.home[this.$store.state.lang.currentLang];
    },
  },

  watch: {
    currentPage() {
      console.log('currentPage');
      const seo = this.currentPage?.pages?.data[0]?.attributes?.Seo;
      document.title = seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', seo?.metaDescription);
      const abschnitte = this.currentPage?.pages?.data[0]?.attributes?.Abschnitte;
      if (abschnitte && abschnitte.length > 0) {
        abschnitte.forEach((abschnitt, index) => {
          if (!abschnitt?.hide && !index) this.selectedCourse = index;
        });
      }
      const { hash } = this.$route;
      if (!hash) window.scrollTo({ top: 0, left: 0 });
      else {
        this.$nextTick(() => {
          setTimeout(() => {
            const el = document.getElementById('prices');
            if (hash && el) {
              console.log('price scroll');
              const top = el.getBoundingClientRect().top + window.scrollY;
              window.scrollTo({ top, left: 0 });
            }
          }, 500);
        });
      }
    },
  },

  methods: {
    fadeIn(el, done) {
      anime({
        targets: el,
        easing: 'easeOutSine',
        duration: 400,
        translateY: [15, 0],
        opacity: [0, 1],
        complete: done,
      });
    },

    fadeOut(el, done) {
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 400,
        translateY: 15,
        opacity: [1, 0],
        complete: done,
      });
    },

    goTo(slug) {
      this.$router.push({ name: 'Pages', params: { lang: this.currentLang, slug } });
    },

    scrollTo(id) {
      const element = document.getElementById(id);
      if (element) window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
    },
  },
};
</script>
