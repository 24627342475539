<template>
<div :id="index" class="h-auto md:h-auto w-full font-rouben text-new-white px-8 md:px-20 mt-20 md:mt-0 mb-20 md:pt-40 flex flex-col md:flex-row">
  <div class="w-full md:w-1/2 h-full flex flex-col items-start" :class="{'md:order-last md:items-start md:ml-32' : index % 2 !== 0}">
    <h1 class="leading-tight tracking-wide w-full md:w-10/12 font-extrabold md:font-bold text-4xl md:text-6xl md:leading-tight text-left mt-5 md:mt-0" >{{titel}}</h1>
    <div v-html="convertMarkdown(text)" class="md:w-9/12 markdown whitespace-pre-line text-base mb-3 leading-7 mt-3 lg:mt-0 text-left" />
    <div class="mt-3 w-full md:w-auto flex flex-row justify-center md:justify-start" :class="{'md:justify-end' : index % 2 !== 0}">
      <customButton v-if="button" :label=button_text @click="navigateTo()" />
    </div>
    <div v-if="couponButton" class="mt-3 w-full md:w-auto flex flex-row justify-center md:justify-start" :class="{'md:justify-end' : index % 2 !== 0}">
      <customButton :label="currentLang === 'de' ? 'Gutschein buchen' : 'Buy gift card'" @click="navigateToCoupon()" />
    </div>
  </div>
  <div v-if="url" class="w-full md:w-1/2 h-auto order-first md:order-none">
    <img v-if="url" :src="chooseImageFormat({ image: url, index: 5 })" class="h-auto object-cover w-full" />
  </div>
</div>
</template>

<script>
// Symbols
import customButton from './symbols/customButton.vue';

// Mixins
import convertMarkdown from '../mixins/convertMarkdown.vue';
import chooseImageFormat from '../mixins/chooseImageFormat.vue';

export default {
  name: 'infoSection',
  props: ['titel', 'text', 'button', 'button_text', 'url', 'index', 'planoId', 'couponButton'],
  components: { customButton },
  mixins: [chooseImageFormat, convertMarkdown],
  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
  },

  methods: {
    navigateTo() {
      if (this.planoId) this.$router.push({ name: 'Book', params: { lang: this.currentLang, slug: this.planoId } });
      else this.$router.push({ name: 'Kontakt', params: { lang: this.currentLang } });
    },

    navigateToCoupon() {
      this.$router.push({ name: 'Book', params: { lang: this.currentLang, slug: 'giftCard' } });
    },
  },
};
</script>
